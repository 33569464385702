.app-header {
  background-color: #282c34;
  color: rgb(0, 0, 0);
  z-index: 10200; 
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px; 
}

.app-title-link {
  text-decoration: none;
  color: inherit;
  z-index: 1;
}

.app-title-link:hover {
  cursor: pointer;
  opacity: 0.8;
}

.app-title {
  font-size: clamp(1rem, 3vw, 2.5rem); 
  margin: 0;
  letter-spacing: 0.02em;
  text-align: left;
  color: #f0e68c;
  position: relative;
  overflow: visible; 
  transition: color 0.3s ease;
  text-shadow: 
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    0 1px 0 #000,
    1px 0 0 #000,
    -1px 0 0 #000,
    0 -1px 0 #000;
  white-space: nowrap; 
}

.app-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #f0e68c;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.app-title:hover {
  color: #ffffff;
}

.app-title:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

@media (max-width: 767px) {
  .header-content {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .app-title-link {
    flex-basis: auto;
    text-align: left;
    margin-bottom: 0;
    margin-right: 10px; 
  }

  .app-title {
    font-size: 1rem; 
  }

  .auth-container {
    justify-content: flex-end;
    flex-grow: 0;
  }
}

.auth-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-language-selector {
  margin-right: 10px;
}

.header-language-selector select {
  width: 100px;
  padding: 0.5px;
  background-color: #1a1a2e;
  color: #33ff33;
  border: 1px solid #ffffff;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media (max-width: 767px) {
  .app-header {
    padding: 5px;
  }

  .app-title {
    font-size: 1.2rem;
  }

  .auth-container button {
    font-size: 0.9rem;
    padding: 5px 10px;
  }
}

.mystic-button {
  background: rgba(44, 44, 44, 0.7);
  border: 1px solid #3a3a3a;
  color: #f0f0f0;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mystic-button:hover {
  background: rgba(58, 58, 58, 0.7);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.welcome-message {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animation-container {
  height: 5rem;
  margin-bottom: 1rem;
}

.welcome-message h1 {
  font-size: 2.5rem;
  margin: 0;
  line-height: 5rem;
}

.welcome-message p {
  font-family: 'jgs7', 'Classic Console', monospace;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.app-main {
  background-color: transparent;
}


.welcome-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1rem;
}

.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-mode .app-header {
  background-color: #2c2c2c;
}

.dark-mode .welcome-message {
  background-color: #1a1a1a;
}

.draw-controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1000;
}

.draw-button {
  padding: 10px 20px;
  font-size: 10px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.draw-button:hover:not(.disabled) {
  background-color: #45a049;
}

.draw-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.next-draw-time {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.dark-mode .next-draw-time {
  color: #aaa;
}

body.feedback-modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
}

body.feedback-modal-open .feedback-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000; 
}

-tooltip.right {
    transform: scale(1) translate(-50%, -50%);
  }

  .card-tooltip.scaling {
    transform: scale(1);
  }