.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modalContent {
  background-color: #1a1a2e;
  padding: 2.5rem;
  border-radius: 20px;
  max-width: 650px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  box-shadow: 0 0 30px rgba(255, 215, 0, 0.3), 0 0 60px rgba(255, 215, 0, 0.1);
  border: 1px solid rgba(255, 215, 0, 0.2);
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 215, 0, 0.7) rgba(0, 0, 0, 0.5); /* Custom scrollbar colors */
  position: relative; /* Add this line */
}

.modalContent::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.modalContent::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5); /* Track color */
  border-radius: 10px; /* Rounded corners for the track */
}

.modalContent::-webkit-scrollbar-thumb {
  background: rgba(255, 215, 0, 0.7); /* Thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 215, 0, 1); /* Thumb color on hover */
}

.modalTitle {
  font-size: 2.8rem;
  color: #ffd700;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  letter-spacing: 2px;
}

.closeButton {
  position: absolute;
  top: 15px; /* Adjust as needed */
  right: 15px; /* Adjust as needed */
  background: none;
  border: 2px solid #ffd700;
  border-radius: 50%;
  font-size: 1.2rem;
  cursor: pointer;
  color: #ffd700;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  z-index: 1; /* Add this line */
}

.closeButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  transform: rotate(90deg) scale(1.1);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
}

.closeButtonIcon {
  font-size: 1.8rem;
}

.drawList {
  list-style-type: none;
  padding: 0;
}

.drawItem {
  cursor: pointer;
  padding: 18px;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.drawItem:last-child {
  border-bottom: none;
}

.drawItem:hover {
  transform: translateX(10px);
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
}

.drawInfo {
  flex-grow: 1;
  color: #e0e0e0;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.drawIcon {
  margin-right: 15px;
  color: #ffd700;
  font-size: 1.4rem;
}

.responseText {
  color: #b7ff10;
  text-shadow: 0 0 5px rgba(0, 255, 13, 0.6);
  font-size: 18px;
  font-family: 'VT323', monospace;
  line-height: 1.6;
  animation: textFlicker 0.01s infinite;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.selectedDrawContent {
  background-color: rgba(30, 30, 60, 0.6);
  border-radius: 15px;
  padding: 2rem;
  margin-top: 1.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.backButton,
.removeButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-family: inherit;
  flex: 1;
  justify-content: center;
  max-width: 48%;
}

.backButton {
  margin-right: 15px;
}

.backButton:hover,
.removeButton:hover {
  background-color: rgba(255, 215, 0, 0.15);
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.4);
  transform: translateY(-2px);
}

.buttonIcon {
  margin-right: 10px;
  font-size: 1.3rem;
}

.noResponse {
  color: #b7ff10;
  font-style: italic;
  text-align: center;
  margin-top: 1rem;
  font-family: 'VT323', monospace;
  font-size: 16px;
}

.robotMonitor {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  transform-style: preserve-3d;
  position: relative;
  background: 
    linear-gradient(to bottom, #2b2b2b, #1a1a1a),
    radial-gradient(ellipse at center, #3a3a3a 0%, #191919 90%);
  background-blend-mode: multiply;
  box-shadow: 
    0 0 0 3px #333,
    0 0 0 6px #222,
    0 0 15px 8px rgba(0, 0, 0, 0.5),
    inset 0 0 100px rgba(0, 0, 0, 0.5);
  transition: height 1.25s ease;
}

.robotMonitor.expanded {
  height: 550px;
}

.expandButtonContainer {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 20;
}

.expandButton {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffd700;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  color: #ffd700;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  transition: all 4s ease;
}

.expandButton:hover {
  background-color: rgba(255, 215, 0, 0.3);
  transform: scale(1.1);
}

.screenContent {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  z-index: 10;
}

.screenOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  z-index: 15;
}

.screenScanlines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.05) 51%);
  background-size: 100% 4px;
  animation: scanlines 10s linear infinite;
  pointer-events: none;
  z-index: 15;
}

@keyframes scanlines {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(4px);
  }
}

@keyframes textFlicker {
  0% {
    opacity: 0.99;
  }
  100% {
    opacity: 1;
  }
}

.confirmDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a2e;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  z-index: 1100;
  text-align: center;
}

.confirmButtons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.confirmButton,
.cancelButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin: 0 0.5rem;
}

.confirmButton:hover,
.cancelButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

@media (max-width: 600px) {
  .modalContent {
    padding: 1.5rem;
  }

  .modalTitle {
    font-size: 2rem;
  }

  .drawInfo {
    font-size: 1rem;
  }

  .backButton,
  .removeButton {
    font-size: 0.9rem;
    padding: 6px 12px;
    max-width: none;
  }
}

/* Add custom scrollbar styles */
.screenContent::-webkit-scrollbar {
  width: 10px;
}

.screenContent::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.screenContent::-webkit-scrollbar-thumb {
  background: rgba(183, 255, 16, 0.5);
  border-radius: 10px;
}

.screenContent::-webkit-scrollbar-thumb:hover {
  background: rgba(183, 255, 16, 0.7);
}