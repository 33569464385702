.howItWorksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 15px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  max-width: 800px;
  margin: 2rem auto;
}

.title {
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.intro {
  font-style: italic;
  text-align: center;
  margin-bottom: 30px;
  color: #ffd700;
}

.processStep {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.processStep:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(255, 215, 0, 0.2);
}

.processStep h3 {
  margin-top: 0;
  color: #ffd700;
  font-size: 1.4rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.3);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.stepNumber {
  display: inline-block;
  background-color: #ffd700;
  color: #1a1a2e;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
  font-weight: bold;
}

.highlight {
  background-color: rgba(255, 215, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  border-left: 4px solid #ffd700;
  margin: 15px 0;
}

.conclusion {
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
  color: #ffd700;
  font-size: 1.2rem;
  padding: 20px;
  background-color: rgba(255, 215, 0, 0.1);
  border-radius: 10px;
}

.backButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  transition: all 0.3s ease;
  align-self: flex-start;
  font-family: inherit;
}

.backButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.backButton::before {
  content: '☽';
  margin-right: 8px;
  font-size: 1.2rem;
}