.captchaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .cardImage {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
  .optionLabel {
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    color: #000000;
  }

  .optionLabel input[type="radio"] {
    margin-right: 10px;
  }
  
  .submitButton {
    padding: 10px 20px;
    background-color: #4a5568;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }

  .submitButton:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }

  .verifiedMessage {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .instructionText {
    text-align: center;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }