.dailyFrequenciesPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #1a1a2e;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
}

.pageHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.pageHeader h1 {
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 0.5rem;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.pageHeader p {
  max-width: 800px;
  margin: 0 auto;
  font-style: italic;
  color: #ffd700;
}

.pageContent {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1rem;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.dateSelector {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.dateSelector input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ffd700;
  border-radius: 4px;
  color: #e0e0e0;
  background-color: #1a1a2e;
}

.dateSelector input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.2);
}

.spreadsSection, .frequenciesSection {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.spreadsSection h2, .frequenciesSection h2 {
  color: #ffd700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
}

.spreadsContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.spreadContainer {
  width: 100%;
  border: 3px solid rgba(255, 215, 0, 0.5);
  border-radius: 20px;
  padding: 1rem;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  background-color: rgba(26, 26, 46, 0.8);
  margin-bottom: 2rem;
}

.spreadContainer h3 {
  color: #ffd700;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.spreadCards {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin: 1rem auto;
}

.spreadCard {
  position: absolute;
  width: 15%;
  max-width: 120px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.spreadCard:hover {
  transform: scale(1.75);
  z-index: 10;
}

.cardImage {
  width: 100%;
  height: 110%;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cardInfo {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(26, 26, 46, 0.9);
  color: #ffd700;
  padding: 8px;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 8px;
  width: 120%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease;
  opacity: 0;
}

.spreadCard:hover .cardInfo {
  opacity: 1;
}

.positionName {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.cardName {
  font-style: italic;
  font-size: 0.85rem;
}

.orientationIcon {
  display: inline-block;
  margin-left: 4px;
  font-size: 0.8rem;
}

.reversed {
  transform: rotate(180deg);
}

/* Three Card Spread Positioning */
.threeCardSpread {
  aspect-ratio: auto;
  height: 300px;
  margin: 1rem auto;
}

.threeCardSpread .spreadCard {
  top: 50%;
  transform: translateY(-50%);
  width: 22%;
  max-width: 100px;
  transition: transform 0.3s ease, z-index 0.3s ease;
}

.threeCardSpread .spreadCard:hover {
  transform: translateY(-50%) scale(1.75);
  z-index: 10;
}

.threeCardSpread .position0 { left: 15%; }
.threeCardSpread .position1 { left: 50%; transform: translate(-50%, -50%); }
.threeCardSpread .position2 { right: 15%; }

/* Adjust mobile positioning for Three Card Spread */
@media (max-width: 768px) {
  .threeCardSpread {
    height: 150px;
  }

  .threeCardSpread .spreadCard {
    width: 25%;
    max-width: 80px;
  }

  .threeCardSpread .position0 { left: 10%; }
  .threeCardSpread .position2 { right: 10%; }
}

/* Celtic Cross Spread Positioning */
.celticCrossSpread .position0 { 
  left: 40%; 
  top: 30%; 
  z-index: 9;
  transition: transform 0.3s ease, z-index 0.3s ease;
}
.celticCrossSpread .position0:hover {
  transform: scale(1.75);
  z-index: 12;
}
.celticCrossSpread .position1 { 
  z-index: 10; 
  left: 39%; 
  top: 30%; 
  transform: rotate(90deg);
  transition: transform 0.3s ease, z-index 0.3s ease;
}
.celticCrossSpread .position1:hover {
  transform: rotate(90deg) scale(1.75);
  z-index: 11;
}
.celticCrossSpread .position2 { left: 40%; top: -10%; }
.celticCrossSpread .position3 { left: 40%; bottom: -5%; }
.celticCrossSpread .position4 { left: 20%; top: 30%; }
.celticCrossSpread .position5 { right: 30%; top: 30%; }
.celticCrossSpread .position6 { right: 10%; top: -25%; }
.celticCrossSpread .position7 { right: 10%; top: 15%; }
.celticCrossSpread .position8 { right: 10%; top: 55%; }
.celticCrossSpread .position9 { right: 10%; top: 95%; }

/* Adjust mobile positioning for Celtic Cross */
@media (max-width: 768px) {
  .celticCrossSpread {
    aspect-ratio: 1 / 1;
    max-width: 90vw;
    max-height: 90vw;
    margin: 0 auto;
  }

  .celticCrossSpread .spreadCard {
    width: 18%;
    max-width: 70px;
  }

  /* Central cross */
  .celticCrossSpread .position0 { left: 30%; top: 40%; }
  .celticCrossSpread .position1 { left: 30%; top: 40%; }
  .celticCrossSpread .position2 { left: 30%; top: 15%; }
  .celticCrossSpread .position3 { left: 30%; bottom: 15%; }
  .celticCrossSpread .position4 { left: 5%; top: 40%; }
  .celticCrossSpread .position5 { left: 55%; top: 40%; }

  /* Staff */
  .celticCrossSpread .position6 { right: 5%; top: 5%; }
  .celticCrossSpread .position7 { right: 5%; top: 30%; }
  .celticCrossSpread .position8 { right: 5%; top: 55%; }
  .celticCrossSpread .position9 { right: 5%; top: 80%; }

  .threeCardSpread .spreadCard {
    width: 25%;
    max-width: 120px;
  }
}

.spreadSeparator {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 215, 0, 0.3);
  margin: 2rem 0;
}

.loading, .error {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 3rem;
  color: #ffd700;
}

@keyframes dots {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.loading span {
  animation: dots 1.5s infinite;
  opacity: 0;
}

.loading span:nth-child(2) {
  animation-delay: 0.5s;
}

.loading span:nth-child(3) {
  animation-delay: 1s;
}

@media (max-width: 768px) {
  .spreadContainer {
    flex-basis: 100%;
  }

  .spreadCards {
    height: 500px;
  }

  .spreadCard {
    width: 20%;
    max-width: 80px;
  }

  .cardInfo {
    font-size: 0.7rem;
  }
}

.barChartContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.barChartItem {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
}

.cardImageWrapper {
  width: 80px;
  height: 138px;
  flex-shrink: 0;
  transition: transform 0.3s ease;
}

.cardImageWrapper:hover {
  transform: scale(1.75);
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.barWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.barLabel {
  font-size: 1rem;
  color: #ffd700;
  font-weight: 500;
  margin-bottom: 5px;
}

.bar {
  height: 30px;
  background: linear-gradient(90deg, #ffd700, #ffaa00);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(255, 215, 0, 0.3);
}

.barValue {
  color: #1a1a2e;
  font-weight: bold;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .barChartItem {
    flex-direction: row;
    align-items: center;
  }

  .cardImageWrapper {
    width: 60px;
    height: 104px;
  }

  .barWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .barLabel {
    font-size: 0.9rem;
  }

  .bar {
    height: 25px;
  }

  .barValue {
    font-size: 0.8rem;
  }

  .threeCardSpread .spreadCard {
    width: 22%;
    max-width: 90px;
  }

  .threeCardSpread .spreadCard:hover {
    transform: translateY(-50%) scale(1.5);
  }

  .threeCardSpread .position0 { left: 10%; }
  .threeCardSpread .position1 { left: 50%; transform: translate(-50%, -50%); }
  .threeCardSpread .position2 { right: 10%; }
}

.backButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  transition: all 0.3s ease;
  align-self: flex-start;
  font-family: inherit;
  display: flex;
  align-items: center;
}

.backButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.backButtonIcon {
  margin-right: 8px;
  font-size: 1.2rem;
}