@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Classic Console';
    src: url('./fonts/clacon2.woff2') format('woff2'),
         url('./fonts/clacon2.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Classic Console', monospace;
  }

  @font-face {
    font-family: 'jgs7';
    src: url('./fonts/jgs7.woff2') format('woff2'),
        url('./fonts/jgs7.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'jgs5';
    src: url('./fonts/jgs5.woff2') format('woff2'),
        url('./fonts/jgs5.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'jgs9';
    src: url('./fonts/jgs9.woff2') format('woff2'),
        url('./fonts/jgs9.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}