.feedbackButton {
  padding: 1px 5px;
  background-color: #1a1a2e;
  color: #ffd700;
  border: 2px solid #000000;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-shadow: 
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    0 1px 0 #000,
    1px 0 0 #000,
    -1px 0 0 #000,
    0 -1px 0 #000;
}

.feedbackButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

/* Footer-specific styles */
:global(.footer) .feedbackButton {
  background-color: transparent;
  border: none;
  color: #ffd700;
  text-decoration: underline;
  padding: 0;
  font-size: 1rem;
}

:global(.footer) .feedbackButton:hover {
  color: #ffffff;
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999; /* Extremely high z-index */
}

.modalContent {
  background-color: #1a1a2e;
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  z-index: 10000000; /* Even higher z-index */
}

/* Add responsive styles for mobile */
@media (max-width: 768px) {
  .modalOverlay {
    align-items: flex-start; /* Align to top on mobile */
    padding-top: 20px; /* Add some top padding */
  }

  .modalContent {
    width: 95%;
    padding: 1.5rem;
    max-height: 80vh;
    margin-top: 20px; /* Add top margin */
  }

  .modalTitle {
    font-size: 1.5rem;
  }

  .closeButton {
    top: 5px;
    right: 5px;
  }
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ffd700;
}

.modalTitle {
  font-size: 2rem;
  color: #ffd700;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.modalContent form {
  display: flex;
  flex-direction: column;
}

.modalContent textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ffd700;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #000000;
}

.modalContent button[type="submit"] {
  padding: 10px;
  background-color: #ffd700;
  color: #1a1a2e;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.3s ease;
}

.modalContent button[type="submit"]:hover {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.modalContent button[type="submit"]:disabled {
  background-color: #4a5568;
  cursor: not-allowed;
}

.successMessage {
  color: #33ff33;
  margin-top: 10px;
  text-align: center;
}

.errorMessage {
  color: #ff3333;
  margin-top: 10px;
  text-align: center;
}

.feedbackIcon {
  margin-right: 5px;
}