.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 15px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
  max-width: 600px;
  margin: 2rem auto;
}

.title {
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 1.5rem;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.email {
  font-size: 1.2rem;
  color: #ffd700;
  text-decoration: none;
  transition: all 0.3s ease;
}

.email:hover {
  color: #ffffff;
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
}

.backButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  transition: all 0.3s ease;
  align-self: flex-start;
  font-family: inherit;
}

.backButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.backButton::before {
  content: '☽';
  margin-right: 8px;
  font-size: 1.2rem;
}