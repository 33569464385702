.resourcesPage {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  background-color: #1a1a2e;
  color: #e0e0e0;
  min-height: 100vh;
}

.pageHeader {
  text-align: center;
  margin-bottom: 2rem;
}

.pageHeader h1 {
  font-size: 3rem;
  color: #ffd700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  letter-spacing: 2px;
}

.pageContent {
  background-color: rgba(30, 30, 60, 0.6);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.2);
}

.introText {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: center;
  font-style: italic;
  color: #b8b8b8;
}

.resourceList {
  list-style-type: none;
  padding: 0;
}

.resourceItem {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(255, 215, 0, 0.2);
  transition: transform 0.3s ease;
}

.resourceItem:last-child {
  border-bottom: none;
}

.resourceItem:hover {
  transform: translateX(10px);
}

.resourceLink {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffd700;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
}

.resourceLink:hover {
  color: #fff;
  text-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
}

.resourceIcon {
  margin-right: 10px;
  font-size: 1.2rem;
}

.resourceDescription {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #b8b8b8;
  padding-left: 25px;
}

.backButton {
  background-color: transparent;
  color: #ffd700;
  border: 2px solid #ffd700;
  border-radius: 25px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 1rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-family: inherit;
}

.backButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
}

.backButtonIcon {
  margin-right: 8px;
  font-size: 1.2rem;
}

@media (max-width: 600px) {
  .resourcesPage {
    padding: 1rem;
  }

  .pageHeader h1 {
    font-size: 2.5rem;
  }

  .pageContent {
    padding: 1.5rem;
  }

  .resourceLink {
    font-size: 1.2rem;
  }
}