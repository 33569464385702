.subscribeButton {
  padding: 1px 5px;
  background-color: #1a1a2e;
  color: #ffd700;
  border: 2px solid #000000;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  text-shadow: 
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000,
    0 1px 0 #000,
    1px 0 0 #000,
    -1px 0 0 #000,
    0 -1px 0 #000;
}

.subscribeButton:hover {
  background-color: rgba(255, 215, 0, 0.1);
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  transform: scale(1.1) rotate(0deg) skew(0.01deg, -0.01deg);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #1a1a2e;
  padding: 2rem;
  border-radius: 15px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  color: #e0e0e0;
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.3);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ffd700;
}

.kindeForm {
  color: #e0e0e0 !important;
}

.kindeForm input,
.kindeForm button {
  color: #e0e0e0 !important;
  background-color: #1a1a2e !important;
  border: 1px solid #ffd700 !important;
}

.kindeForm button[type="submit"] {
  background-color: #ffd700 !important;
  color: #1a1a2e !important;
  border: none !important;
}

.successMessage {
  color: #33ff33;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}